import React from 'react'
import { GatsbySeo } from 'gatsby-plugin-next-seo'

import TermsPages from '../../partials/Terms/About'
import { dataTermsIndique30 } from '../../data/terms/terms-indique-30'

const TermsIndique30 = () => (
  <>
    <GatsbySeo nofollow={true} noindex={true} />
    <TermsPages
      data={dataTermsIndique30}
      linkCanonical="termos-indique-e-ganhe/30"
    />
  </>
)
export default TermsIndique30
