import { TermsProps } from '../../@interface/terms'

const recompensa = `R$30,00 (trinta reais)`
export const dataTermsIndique30: TermsProps = {
  title: 'Termos e Condições da campanha Indique e Ganhe',
  subtitle: 'da campanha Indique e Ganhe',
  update: '01 de outubro de 2021',
  about: {
    title: 'Termos e Condições da campanha Indique e Ganhe',
    introduction: `
    A campanha “Indique e Ganhe” é realizada pela Cora Sociedade de Crédito, Financiamento e Investimento S.A., inscrita no CNPJ sob o nº 37.880.206/0001-63, com sede na Cidade de São Paulo, Estado de São Paulo, Rua Frei Caneca, 1246, 3º andar, sala 31 - Consolação - São Paulo - SP – CEP 01307-002, doravante denominada simplesmente “Cora”.
    <br/>
    Para os fins desta campanha os seguintes termos possuirão os seguintes significados:
    <br/><br/>
    <b>Aplicativo Cora:</b> Aplicativo mobile disponibilizado pela Cora para que você possa gerenciar a Conta Cora de sua empresa.
    <br/><br/>
    <b>Recompensa:</b> Cada indicação válida de um (01) CNPJ consiste no recebimento do valor de ${recompensa} na Conta Cora do Cliente Cora que indicou. Esta recompensa por indicação está condicionada ao atendimento de todas as condições previstas nesta campanha.
    <br/><br/>
    <b>Cliente Cora:</b> Todo aquele Usuário do Aplicativo Cora, que possua cadastro ativo e regular e que utilizou o link disponibilizado no Aplicativo Cora para a indicação. O conceito também se aplica à expressão <b>“Usuário”</b>.
    <br/><br/>
    <b>Empresa Indicada:</b> Todo aquele Novo Usuário que for indicado através de link especial, fornecido pelo Cliente Cora, que se cadastre e faça uma movimentação em sua nova conta Cora. O conceito também se aplica à expressão “Novo Usuário” e “Empresa(s)”. Fica entendido que Empresa Indicada representa a indicação de um (01) CNPJ pelo Cliente Cora, logo, caso duas pessoas sócias de uma Empresa Indicada criarem cadastro na Cora, será contabilizado apenas uma (01) indicação para a campanha.
    <br/><br/>
    <b>Indicação Válida:</b> Cadastro da Empresa Indicada, ou seja, um (01) CNPJ cadastrado pela primeira vez, através do link recebido pelo Usuário e a realização de uma (01) movimentação em sua nova conta Cora.
    <br/><br/>
    A campanha “Indique e Ganhe” independe de qualquer modalidade de sorteio, vale-brinde, concurso ou operação assemelhada, não estando, portanto, sujeito à autorização prévia estabelecida no artigo 1º da Lei Federal nº 5.768/71, beneficiando indistintamente a todos os que cumprirem ao disposto neste Regulamento.
    <br/>
    <b>Ao participar da campanha, você concorda com o presente Termos e Condições para a campanha “Indique e Ganhe”.</b>
    `,
  },
  section: [
    {
      list: [
        {
          head: 'Do objeto da campanha',
          items: [
            {
              item: `A campanha consiste no Cliente Cora indicar 5 (cinco) Empresas e receber, como recompensa, ${recompensa} por cada Indicação Válida, ou seja, desde que esta Empresa se torne um Novo Usuário.`,
            },
            {
              item: `O Cliente Cora poderá acompanhar, por meio do Aplicativo Cora, a evolução das indicações e o recebimento dos ${recompensa} correspondente, que será feito em até 07 (sete) dias úteis, após verificação do atendimento dos requisitos elencados na Cláusula 2.`,
            },
          ],
        },
        {
          head: 'Condições para a participação',
          items: [
            {
              item: `São condições cumulativas para participação na campanha:
              <br/>
              <b>a)</b> Estar de acordo com os <a href="/termos-uso" alt="Termos e Condições de Uso">Termos e Condições de Uso</a> e <a href="/politica-privacidade" alt="Política de Privacidade">Política de Privacidade</a> da Cora;
              <br/>
              <b>b)</b> O Cliente Cora estar com o seus dados junto ao cadastro no Aplicativo Cora corretos e atualizados;
              <br/>
              <b>c)</b> Ser Cliente Cora já cadastrado e utilizar o link de indicação da campanha disponibilizado no Aplicativo Cora;
              <br/>
              <b>d)</b> Indicar uma Empresa que não seja Cliente Cora, ou seja, um Novo Usuário;
              <br/>
              <b>e)</b> Que a Empresa Indicada se cadastre pela primeira vez, através do link recebido pelo Usuário e realize uma movimentação em sua nova conta Cora, considerando-se então como indicação válida. Importante esclarecer que o próprio Cliente Cora é quem deverá encaminhar o link à Empresa que pretende indicar.`,
            },
          ],
        },
        {
          head: 'Do prazo e da área de execução da campanha',
          items: [
            {
              item: `Esta campanha é válida a partir do dia 09 de Maio de 2022 até o dia 03 de Junho de 2022 em todo o território nacional, ou seja, serão contabilizadas para cumprir os critérios para a recompensa de ${recompensa} apenas as Indicações Válidas que criarem conta na Cora e realizarem uma (01) movimentação durante o período.`,
            },
          ],
        },
        {
          head: 'Restrições da campanha',
          items: [
            {
              item: `Não será devida a recompensa de ${recompensa} se:
              <br/>
              <b>a)</b> A Empresa Indicada não se cadastrar e não realizar uma (01) movimentação na nova conta Cora;
              <br/>
              <b>b)</b> A Empresa Indicada já integrar a base de clientes da Cora, ou seja, o CNPJ indicado já constar na base antes da indicação.
              `,
            },
            {
              item: `Importante esclarecer que para que a indicação seja considerada válida, o Cliente Cora deve encaminhar à Empresa Indicada o link disponibilizado no Aplicativo Cora para a indicação.`,
            },
            {
              item: `Não será devida o valor de ${recompensa} caso fique constatada fraude na campanha, como por exemplo, mas não se limitando, ao cadastro indevido da Empresa Indicada, feito com dados de terceiro que não estava de acordo com esse cadastro.`,
            },
          ],
        },
        {
          head: 'Da apuração da recompensa',
          items: [
            {
              item: `Os Usuários que atenderem às condições da Cláusula 2 poderão receber ${recompensa} por Indicação Válida, após apuração realizada pela Cora, conforme Cláusula 5.2. É importante ressaltar que o valor de ${recompensa} é devido apenas ao Cliente Cora que faz a Indicação Válida, e não à Empresa Indicada.`,
            },
            {
              item: `O recebimento dos ${recompensa} será feito em até 07 (sete) dias úteis após a Indicação Válida da Empresa Indicada, conforme Cláusula 2.`,
            },
            {
              item: `O Cliente Cora reconhece que a Cora não poderá ser responsabilizada por eventual atraso no recebimento de ${recompensa}, causado pela própria Empresa Indicada, como, por exemplo, mas não se limitando, em razão do cadastro de dados incorretos ou não aceitáveis ou outras inconsistências cadastrais.`,
            },
            {
              item: `A recompensa de ${recompensa} conquistada com as indicações poderá ser cessada em casos de:
              <br/>
              <b>a)</b> uso da Conta Cora para atividades ilegais e/ou suspeitas;
              <br/>
              <b>b)</b> inatividade do uso da Conta Cora e do Cartão Cora por mais de 12 (doze) meses;
              <br/>
              <b>c)</b> encerramento da Conta Cora;
              <br/>
              <b>e)</b> ou qualquer atividade em desacordo com os <a href="/termos-uso" alt="Termos de Uso da Conta">Termos de Uso da Conta</a>.
              `,
            },
          ],
        },
      ],
    },
  ],
}
